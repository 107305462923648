import React, {FC, useEffect, useMemo, useState} from "react";
import {Switch} from "../../Switch";
import styled from "styled-components";
import {useHistory, useParams} from "react-router-dom";
import {useClientProductsProvider} from "../../../providers/ClientProductsProvider";
import {AccessTokenOptions, APIS, saveClientAddOn, saveClientPackages, saveClientRole,} from "../../apiHelpers";
import SecondaryHeaderAndButtons from "../SecondaryHeaderAndButtons";
import {useApi} from "../../../hooks/useApi";
import PackageRolesDropdown from "./PackageRolesDropdown";
import IncludedFeatures from "./IncludedFeatures";
import ProductAddOns from "./ProductAddOns";
import _ from "lodash";
import {useClientAddOnsProvider} from "../../../providers/ClientAddOnsProvider";
import {useProductVerticalsProvider} from "../../../providers/ProductVerticalsProvider";
import {findProductByPrimaryRoleId} from "../../../utils";
import {useAuth0} from "@auth0/auth0-react";
import {trackPromise} from "react-promise-tracker";
import {orderPackages, productIdToRoleNameFilterMap} from "../../utils";
import {Dropdown} from "@stats/playbook-components";
import {MenuItem} from "../../EditLeagues/SelectorMenu";
import {
  Role,
  RolesResponse,
  Scope,
} from "../../ProductPage/ProductPage.types";

const SwitchWrapper = styled.div`
  display: flex;

  span {
    padding: 4px 0 0 8px;
  }
`;

const StyledPackageDetails = styled.div`
  .package-selection {
    padding: 32px 0;
  }

  .packages-dropdown {
    width: 248px;
    cursor: pointer;
  }
`;

const PackageDetails: FC = () => {
  
  const {clientHash, productId} = useParams<{ clientHash: string; productId: string }>();
  const {clientProducts, retriggerProductsAPICall} = useClientProductsProvider();
  const {clientAddOns, retriggerAddOnsAPICall} = useClientAddOnsProvider();
  
  const history = useHistory();
  
  const [enabled, setEnabled] = useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState<string>("N/A");
  const [allAddOns, setAllAddOns] = useState<string[]>([]);
  const [originalEnabledSetting, setOriginalEnabledSetting] = useState<boolean>(false);
  const [originalPackage, setOriginalPackage] = useState<string>();
  const [originalAddOns, setOriginalAddOns] = useState<string[]>([]);
  const [newAddOns, setNewAddOns] = useState<string[]>([]);
  
  const isEnabledText = enabled ? "Application Enabled" : "Application Disabled";
  
  const {totalProducts} = useProductVerticalsProvider();
  
  const product = findProductByPrimaryRoleId(totalProducts, productId);
  const productName = product.id;
  const nameFilter = productIdToRoleNameFilterMap[productName] || productName
  const {data: packages} = useApi<RolesResponse>(
    `${APIS.AUTH0}?service=roles&name_filter=${nameFilter}&include_permissions=true`
  );
  const packageRoles: Role[] =
    useMemo(() => packages?.roles.roles.filter((x) => !x.name.includes("AddOn")) || [], [packages])

  
  const addOnRoles: string[] = allAddOns
  const {data: clientProductPackages} = useApi<{
    clientProductPackage: string[];
  }>(
    `${APIS.AUTH0}?service=clientProductPackage&client_hash=${clientHash}&product_id=${productId}`
  );
  const [selectedPackage, setSelectedPackage] = useState<string>();
  const {getAccessTokenSilently} = useAuth0();
  
  useEffect(() => {
    const packageId = clientProductPackages?.clientProductPackage[0];
    setSelectedPackage(packageId);
    setOriginalPackage(packageId);
  }, [clientProductPackages]);
  
  useEffect(() => {
    if (clientProducts) {
      const clientRole = clientProducts.find(
        (clientRole) => clientRole.productId === productId
      );
      setEnabled(clientRole ? clientRole.isEnabled : false);
      setOriginalEnabledSetting(clientRole ? clientRole.isEnabled : false);
      setLastUpdated(clientRole ? clientRole.lastUpdated : "N/A");
    }
    if (clientAddOns) {
      const addOnNames = clientAddOns
        .filter((x) => x.isEnabled)
        .map((x) => x.addOn);
      setOriginalAddOns(addOnNames);
      setNewAddOns(addOnNames);
    }
  }, [clientProducts, productId, packages, clientAddOns]);
  
  const getResourceServer = async (productUrl: string): Promise<any> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions)
    );
    
    const url = `${APIS.AUTH0}?service=resource_servers&rs_id=${productUrl}`;
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
  }
  
  useEffect(() => {
    product?.url && getResourceServer(product.url)
      .then((res) => {
        if (res.ok) {
          return res
        }
        throw(new Error('Error'))
      })
      .then((response) => {
        return response?.json()
      })
      .then((x) => {
        setAllAddOns(
          x.resourceServer.scopes.filter((permission: Scope) => {
            return permission?.value?.includes('AddOn')
          }).map((p: Scope) => p.value)
        )
      }).catch();
  }, [product])
  
  const saveClicked = async (): Promise<void> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions)
    );
    if (enabled !== originalEnabledSetting) {
      saveClientRole(
        {clientHash, productId, isEnabled: enabled},
        accessToken
      ).then(() => setOriginalEnabledSetting(enabled));
    }
    if (selectedPackage && selectedPackage !== currentPackageForSport) {
      const otherSportPackages = clientProductPackages?.clientProductPackage.filter(x => x !== currentPackageForSport) || []
      const updatedPackages = selectedPackage !== 'default' ? [...otherSportPackages, selectedPackage] : otherSportPackages
      
      saveClientPackages(
        {
          clientHash,
          productId,
          packageId: updatedPackages.join(',') || '',
        },
        accessToken
      ).then(() => setOriginalPackage(selectedPackage));
    }
    if (!_.isEqual(_.sortBy(newAddOns), _.sortBy(originalAddOns))) {
      addOnRoles.forEach((x) => {
        saveClientAddOn({
            clientHash,
            addOn: x,
            isEnabled: newAddOns.includes(x),
          },
          accessToken
        ).then(() => setOriginalAddOns(newAddOns));
      });
    }
    retriggerProductsAPICall();
    retriggerAddOnsAPICall();
  };
  
  const allSports: MenuItem[] = [
    {
      value: 'Soccer',
      displayValue: 'Soccer'
    },
    {
      value: 'Basketball',
      displayValue: 'Basketball'
    },
    {
      value: 'NFL',
      displayValue: 'NFL'
    },
    {
      value: 'NCAAF',
      displayValue: 'NCAAF'
    },
    {
      value: 'Cricket',
      displayValue: 'Cricket'
    },
    {
      value: 'Tennis',
      displayValue: 'Tennis'
    },
  ]
  
  const [selectedSport, setSelectedSport] = useState<string>('Soccer')
  
  const availablePackagesForSport = useMemo(() => {
    const filteredPackages = packageRoles.filter(role => role.name.includes(selectedSport.toUpperCase()))
    return orderPackages(filteredPackages)
  }, [selectedSport, packageRoles])
  
  const currentPackageForSport = useMemo(() => {
    return clientProductPackages?.clientProductPackage.find(x => availablePackagesForSport.find(y => y.id === x))
  }, [availablePackagesForSport, clientProductPackages])
  
  
  return (
    <StyledPackageDetails>
      <SecondaryHeaderAndButtons
        title="Package Details"
        lastUpdated={lastUpdated}
        onClickSave={saveClicked}
        onClickCancel={(): void => history.push(`/clients/${clientHash}`)}
        enabledSaveButton={
          selectedPackage !== originalPackage ||
          enabled !== originalEnabledSetting ||
          !_.isEqual(_.sortBy(newAddOns), _.sortBy(originalAddOns))
        }
      />
      <SwitchWrapper>
        <Switch checked={enabled} changeChecked={setEnabled}/>
        <span>{isEnabledText}</span>
      </SwitchWrapper>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
        <Dropdown
          style={{width: '25%', paddingRight: '16px'}}
          value={selectedSport}
          menuItems={allSports}
          label='Sport'
          onItemSelect={setSelectedSport}
        />
        <PackageRolesDropdown
          clientProductPackageId={currentPackageForSport}
          packageRoles={availablePackagesForSport}
          setSelectedPackage={setSelectedPackage}
        />
      </div>
      <IncludedFeatures
        packageRoles={availablePackagesForSport}
        selectedPackage={selectedPackage}
      />
      <ProductAddOns
        addOnNames={addOnRoles}
        newAddOns={newAddOns}
        setNewAddOns={setNewAddOns}
      />
    </StyledPackageDetails>
  );
};

export default PackageDetails;
